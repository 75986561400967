/* Select styles */
.inputWrap {
  @apply relative;

  &.full {
    .input {
      @apply w-full;
    }
  }

  &.hasError {
    :global {
      .ui-select .ui-select__control {
        @apply border-amber-800;
      }
    }
  }

  :global {
    .ui-select {
      &.ui-select__default.ui-select__sm .ui-select__control {
        min-height: 22px;
      }
      &.ui-select__default.ui-select__md .ui-select__control {
        min-height: 30px;
      }
      &.ui-select__default.ui-select__lg .ui-select__control {
        height: 60px;
        min-height: 60px;
        @apply rounded-xl p-3;
      }

      &.ui-select__default.ui-select__text-xs .ui-select__value-container {
        @apply text-xs leading-4;
      }
      &.ui-select__default.ui-select__text-sm .ui-select__value-container {
        @apply text-sm leading-5;
      }
      &.ui-select__default.ui-select__text-base .ui-select__value-container {
        @apply text-base leading-6;
      }
      &.ui-select__default.ui-select__text-lg .ui-select__value-container {
        @apply text-lg leading-7;
      }

      // Default
      &.ui-select__default {
        @apply w-full;
      }

      &.ui-select__default .ui-select__control {
        @apply h-[30px] w-full rounded-md py-1 px-2 border border-solid border-transparent flex items-center bg-transparent transition cursor-pointer;
        @apply outline-none hover:ring-white/[0.4] hover:ring-1;
        min-height: 30px;
        box-shadow: none;
        background-color: rgba(255, 255, 255, 0.08);

        &:hover {
          & .ui-select__placeholder, & .ui-select__indicator {
            color: rgba(255, 255, 255, 0.7);
          }
        }

      }

      &.ui-select__default .ui-select__control--is-focused {
        @apply ring-white/[0.4] ring-1;

        & .ui-select__placeholder, & .ui-select__indicator {
          @apply text-white #{!important};
        }
      }

      &.ui-select__default .ui-select__control--menu-is-open {
        @apply text-white bg-white/[0.16];

        & .ui-select__indicator {
          @apply text-white #{!important};

          transform: rotate(0deg);
        }
      }

      &.theme-ui-select .ui-select__control {
        @apply border-none outline-none hover:ring-0;
        background-color: #15152B;

        &::before,
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          transition: opacity 0.3s ease-in-out;
          top: 0;
          left: 0;
          right: 0;
          z-index: -1;
          margin: -1px;
          border-radius: inherit;

          @media not all and (min-resolution: 0.001dpcm) {
            transition: none;
          }
        }

        &:hover {
          &::after {
            opacity: 1;
          }
        }

        &::after {
          opacity: 0;
        }
      }

      &.ui-select__default.ui-select__lg.theme-ui-select .ui-select__control {
        min-height: 58px;
        @apply rounded-xl p-3;
      }

      &.theme-ui-select .ui-select__control--is-focused {
        @apply ring-0;

        &::after {
          opacity: 1;
        }
      }

      &.ui-select__default .ui-select__multi-value {
        @apply mt-0 mb-0 flex items-center;

        padding: 2px 6px;
        border-radius: 2px;
        background-color: rgba(255, 255, 255, 0.08);
        border-color: rgba(255, 255, 255, 0.9);
      }

      &.ui-select__default .ui-select__multi-value .ui-select__multi-value__label {
        @apply text-white text-xs leading-3 font-medium p-0;
      }

      &.ui-select__default .ui-select__multi-value .ui-select__multi-value__remove {
        @apply text-white p-0 bg-transparent;

        margin-left: 6px;
        font-size: 10px;
      }

      // Basic
      &.ui-select__basic .ui-select__control {
        @apply rounded-md p-0 border border-solid border-transparent flex items-center bg-transparent transition cursor-pointer;

        min-height: auto;
        box-shadow: none;

        &:hover {
          & .ui-select__placeholder, & .ui-select__indicator {
            color: rgba(255, 255, 255, 0.7);
          }
        }
      }

      &.ui-select__basic .ui-select__control--menu-is-open {
        @apply text-white;

        .ui-select__indicator {
          @apply text-white;
        }
      }

      &.ui-select__basic .ui-select__multi-value {
        @apply mt-0 mb-0 flex items-center;

        padding: 2px 6px;
        border-radius: 2px;
        background-color: rgba(255, 255, 255, 0.08);
        border-color: rgba(255, 255, 255, 0.9);
      }

      &.ui-select__basic .ui-select__multi-value .ui-select__multi-value__label {
        @apply text-white text-xs leading-3 font-medium p-0;
      }

      &.ui-select__basic .ui-select__multi-value .ui-select__multi-value__remove {
        @apply text-white p-0 bg-transparent;

        margin-left: 6px;
        font-size: 10px;
      }

      &.ui-select__basic .ui-select__menu {
        @apply top-[0] -mt-1 w-auto;
      }

      & .ui-select__value-container {
        @apply text-white p-0;
      }

      & .ui-select__input {
        color: white !important;
      }

      & .ui-select__placeholder {
        @apply m-0;
        color: rgba(255, 255, 255, 0.6);
      }

      & .ui-select__single-value {
        @apply text-white leading-5 m-0;
      }

      & .ui-select__indicator {
        @apply transition p-0 pl-1 -mr-1;

        color: rgba(255, 255, 255, 0.6);
      }

      & .ui-select__menu {
        @apply p-1 rounded-md top-[90%];

        background: #2A2635;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);

      }

      & .ui-select__menu-list {
        @apply p-1 m-0;
      }

      & .ui-select__option {
        @apply cursor-pointer flex items-center px-2 py-[9px] mt-1 first:mt-0 text-xs leading-3;

        font-size: 12px !important;
        border-radius: 2px;

        &:active {
          background-image: linear-gradient(0deg, rgba(0, 0, 24, 0.1), rgba(0, 0, 24, 0.1)), linear-gradient(180deg, #F47D02 0%, #FB6101 100%);
        }
      }

      .ui-select__option--is-selected  {
        background-color: transparent;
        background-image: none;
      }

      .ui-select__option--is-focused {
        background-image: linear-gradient(180deg, #F47D02 0%, #FB6101 100%);
      }

      &.ui-select--is-disabled {
        & .ui-select__control {
          @apply border-transparent cursor-not-allowed;
        }
        & .ui-select__indicator {
          color: rgba(255, 255, 255, 0.18);
        }

        & .ui-select__placeholder, & .ui-select__single-value {
          color: rgba(255, 255, 255, 0.18);
        }
      }
    }
  }
}
