/* RadioGroup styles */
.radioGroup {
  @apply bg-white/[0.08] rounded-md p-[3px] flex gap-[3px];
}

.radioButton {
  @apply relative flex-grow;
}
.input {
  @apply opacity-0 absolute top-0 left-0 right-0 bottom-0;

  &:not(:disabled) {
    @apply cursor-pointer;
  }

  &:checked ~ .label {
    @apply bg-white/[0.08] rounded-[3px];
  }

  &:hover:not(:checked) ~ .label {
    @apply bg-white/[0.04] rounded-[3px];
  }
  &:focus ~ .label {
    @apply ring-white/[0.4] ring-1;
  }
}
.label {
  @apply py-1 px-3 duration-200 flex items-center justify-center text-sm w-full whitespace-nowrap;
}
