/* Input styles */

.inputWrap {
  @apply relative;

  &.hasPrefix {
    .input {
      @apply pl-[30px];
    }
  }

  &.hasSuffix {
    .input {
      @apply pr-[30px];
    }
  }

  &.full {
    .input {
      @apply w-full;
    }
  }

  &.hasError {
    .input {
      background-color: rgba(32, 32, 32, 0.2);
      border-color: #FF2420;
    }
  }
}
.input {
  height: 40px;
  background: rgba(255, 255, 255, 0.08);
  vertical-align: middle;
  border-color: rgba(255, 255, 255, 0.9);

  @apply text-white;
  @apply rounded-md px-[10px] py-2.5 font-medium text-xs leading-3;
  @apply box-border border border-solid border-transparent transition-all outline-none;

  &:hover {
    border-color: #64D2FF;
  }

  &:focus {
    @apply text-white;

    border-radius: 6px;
    background-image: linear-gradient(#2A2635, #2A2635),
    linear-gradient(#52EDC7, #5AC8FB);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-color: transparent;
  }

  &:disabled {
    color: rgba(255, 255, 255, 0.18);
  }

  &::placeholder {
    border-color: rgba(255, 255, 255, 0.6);
  }

  @media (min-width: 640px) {
    height: 30px;
  }
}

.prefix {
  /* Doesn't work with tailwind for some reason */
  transform: translateY(-50%);

  @apply block absolute top-1/2 left-[10px];

  & > figure {
    @apply block;
  }
}
.suffix {
  /* Doesn't work with tailwind for some reason */
  transform: translateY(-50%);

  @apply absolute right-[10px] top-1/2;

  & > figure {
    @apply block;
  }
}
