/* SelectSubaccount styles */

/* DropdownMenu styles */
.button {
  @apply outline-none rounded-md py-2 px-2 text-sm flex items-center justify-between transition-all duration-200 bg-white/[0.08];
  @apply focus:ring-white/[0.4] focus:ring-1 hover:ring-white/[0.4] hover:ring-1;

  &[aria-expanded="true"] {
    @apply bg-white/[0.16] ring-white/[0.4] ring-1;
  }
}

.listTitle {
  @apply text-xs font-medium text-white/[0.6] mt-2 mb-1 p-1 w-full;
}

.listContainer {
  @apply overflow-auto max-h-[260px];
  &::-webkit-scrollbar{
    display: none;
  }
}

.list {
  @apply mt-1 p-1 rounded-md backdrop-blur-lg w-[287px] z-70;

  scroll-behavior: smooth;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);
  outline: none;
}

.item {
  @apply w-full px-3 pt-[9px] pb-[9px] cursor-pointer text-xs leading-3 rounded-sm flex;
  @apply items-center;
  @apply outline-none;

  .arrowTo {
    @apply opacity-0;
  }

  &:hover {
    background: linear-gradient(180deg, #F47D02 0%, #FB6101 100%);

    .arrowTo {
      @apply opacity-100;
    }
  }

  &:focus, &:active {
    background-image: linear-gradient(0deg, rgba(0, 0, 24, 0.1), rgba(0, 0, 24, 0.1)), linear-gradient(180deg, #F47D02 0%, #FB6101 100%);

    .arrowTo {
      @apply opacity-100;
    }
  }
}

.item[data-selected],
.item.active {
  background: linear-gradient(180deg, #F47D02 0%, #FB6101 100%);
}
