.item {
  @apply text-white transition-all delay-150;
  @apply px-3.5 py-2 flex items-center rounded-md;
  @apply hover:bg-white/[0.08] focus:bg-white/[0.08] outline-none ring-transparent ring-1;
  @apply md:mx-1 xl:mx-4;
  @apply text-xs md:text-base;
  
  &:hover,
  &:focus {
    @apply ring-white/[0.4];
  }

  &.active {
    @apply bg-white/[0.16];
  }
}