/* Box styles */
.box {
  @apply backdrop-blur-[40px] rounded-xl;
  @apply outline-none;

  // color themes
  &.primary {
    @apply bg-white/[0.04];
  }
  &.warning {
    @apply bg-[#F47D02]/[0.3];
  }

  // spacing
  &.sm {
    @apply p-2;
  }

  &.base {
    @apply p-3;
  }

  &.withBorder {
    @apply border border-white/[0.1]
  }
}
