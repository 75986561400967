/* List styles */
.list {
  @apply rounded-[10px] overflow-x-hidden;
}
.item {
  &:first-child .itemLink {
    @apply rounded-t-[10px];
  }
  &:last-child .itemLink {
    @apply rounded-b-[10px];
  }

  &:not(:last-child) {
    &.xs {
      @apply mb-[1px];
    }
    &.sm {
      @apply mb-0.5;
    }
  } 
}
.itemLink {
  @apply p-3 outline-none w-full flex justify-between items-center;

  &.primary {
    @apply bg-white/[0.04];
  }

  &.secondary {
    @apply bg-white/[0.08];
  }

  @media (hover: none) {
    &:active {
      @apply bg-[#f47d02]/[0.3];
    }
  }
  @media (hover: hover) {
    &:hover,
    &:focus {
      @apply bg-[#f47d02]/[0.3];
    }
  }

  &[data-highlighted],
  &.active {
    @apply bg-[#f47d02]/[0.3];
  }
}
